import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {Link} from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from "lodash/isEmpty";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation} from "react-i18next";


const ACTION_TYPE_ICONS = {
    1: 'pe-7s-ticket',  // ADD_TAG
    2: 'pe-7s-trash',  // REMOVE_TAG
    3: 'pe-7s-play',  // SUBSCRIBE_TO_FLOW
    4: 'pe-7s-close-circle',  // UNSUBSCRIBE_FROM_FLOW
    5: '',  // MARK_THREAD_OPEN
    6: '',  // MARK_THREAD_DONE
    7: '',  // NOTIFY_ADMINS
    8: '',  // SET_CUSTOM_FIELD
    9: '',  // CLEAR_CUSTOM_FIELD
    10: 'pe-7s-next',  // SUBSCRIBE_TO_BOT
    11: 'pe-7s-back',  // UNSUBSCRIBE_FROM_BOT
    12: 'lnr-power-switch',  // HTTP_REQUEST
};

const ACTION_TYPE_SEMICOLON = {
    1: true,  // ADD_TAG
    2: true,  // REMOVE_TAG
    3: true,  // SUBSCRIBE_TO_FLOW
    4: true,  // UNSUBSCRIBE_FROM_FLOW
    5: true,  // MARK_THREAD_OPEN
    6: true,  // MARK_THREAD_DONE
    7: true,  // NOTIFY_ADMINS
    8: true,  // SET_CUSTOM_FIELD
    9: true,  // CLEAR_CUSTOM_FIELD
    10: false,  // SUBSCRIBE_TO_BOT
    11: false,  // UNSUBSCRIBE_FROM_BOT
    12: true,  // HTTP_REQUEST
};


class ActionNodeWidget_ extends AbstractNodeWidget {
    constructor(props) {
        super(props);

        this.type = 'action-node';
    }

    getHeaderClassName() {
        return 'bg-sunny-morning bg-opacity-3';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-gleam"> </i> {options.step.name || 'Action'}
        </>;
    }

    getBody() {
        const options = this.props.node.options;
        const actionsHtml = options.step.actions.map(
            (el, idx) => <div className='d-flex align-items-center py-2' key={idx}>
                <i className={`mr-2 fsize-2 position-relative text-warning font-weight-bold opacity-9 ${ACTION_TYPE_ICONS[el.type.id]}`} style={{bottom: -1}}/>
                <span>
                    {el.type.name}{ACTION_TYPE_SEMICOLON[el.type.id] && ':'}&nbsp;{' '}
                    <strong>
                        {get(el, 'tag.name')}
                        {
                            get(el, 'flow.name') && <Link to={`/${options.step.shop_id}/flows/${el.flow.id}/`} onClick={e => e.stopPropagation()}>
                                {el.flow.name}
                            </Link>
                        }
                    </strong>
                </span>
            </div>
        );

        return <>
            {actionsHtml}
        </>;
    }

    getErrors() {
        const options = this.props.node.options;

        if (isEmpty(options.step.actions)) {
            return <i className='opacity-5'>
                {this.props.t('pages.main.flow_builder.node.action.no_operations', 'No operations specified')}
            </i>;
        }
    }
}

export const ActionNodeWidget = withTranslation()(ActionNodeWidget_);


export class ActionNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'action-node',
            disableCascadePort: true,
        });
    }
}


export class ActionNodeFactory extends AbstractReactFactory {
    constructor() {
        super('action-node');
    }

    generateModel(event) {
        return new ActionNodeModel();
    }

    generateReactWidget(event) {
        return <ActionNodeWidget engine={this.engine} node={event.model}/>;
    }
}
