import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import get from 'lodash/get';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {store} from "../../../../configureStore";
import {withTranslation} from "react-i18next";


class PluginNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'plugin-node';
    }

    getHeaderClassName() {
        const stepType = this.getStepType();
        return `${get(stepType, 'html_bg_class')} bg-opacity-3`;
    }

    getHeader() {
        const options = this.props.node.options;
        const stepType = this.getStepType();
        return <>
            <i className={get(stepType, 'html_icon') || "pe-7s-diamond"}> </i> {options.step.name || '...'}
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const stepType = this.getStepType();

        const text = get(options.step.text, store.getState().Shops.activeShop.lang_by_default);
        const imageUrl = options.step.image_url;
        const fileUrl = options.step.file_url;
        const videoUrl = options.step.video_url;

        if (!text && !imageUrl && !fileUrl && !videoUrl) {
            return <div className="d-flex justify-content-center align-middle pt-2">
                <i className={`lnr-cog icon-gradient ${get(stepType, 'html_bg_class')}`} style={{fontSize: '45px'}}/>
            </div>;
        }
    }
}

export const PluginNodeWidget = withTranslation()(PluginNodeWidget_);


export class PluginNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'plugin-node',
        });
    }
}


export class PluginNodeFactory extends AbstractReactFactory {
    constructor() {
        super('plugin-node');
    }

    generateModel(event) {
        return new PluginNodeModel();
    }

    generateReactWidget(event) {
        return <PluginNodeWidget engine={this.engine} node={event.model}/>;
    }
}
