// Shop
export const SHOP_SOURCE_HIGHTOUCH = 'hightouch';
export const SHOP_SOURCE_CHAT_API = 'chat_api';
export const SHOP_SOURCE_RETAIL_CRM_WABA_RU = 'retail_crm_waba_ru';
export const SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU = 'retail_crm_instagram_ru';

// Bot
export const BOT_TYPE_TG = 1;
export const BOT_TYPE_WA = 2;
export const BOT_TYPE_FB = 3;
export const BOT_TYPE_VK = 4;
export const BOT_TYPE_AVITO = 5;
export const BOT_TYPE_WABA = 6;
export const BOT_TYPE_VIBER = 8;
export const BOT_TYPE_IG = 9;
export const BOT_TYPE_OK = 10;
export const BOT_TYPE_EXPRESS = 11;
export const BOT_TYPE_RETAILCRM = 12;

export const BOT_STATUS_CREATED = 1;
export const BOT_STATUS_RUNNING = 5;
export const BOT_STATUS_STOPPED = 3;

export const ADAPTER_STATUS_ERROR = -1;
export const ADAPTER_STATUS_STOPPED = 0;
export const ADAPTER_STATUS_VK_GROUP_NO_CALLBACK = 1;
export const ADAPTER_STATUS_VK_GROUP_NO_EVENTS = 2;
export const ADAPTER_STATUS_VK_GROUP_BOT_MESSAGES_DISABLED = 3;
export const ADAPTER_STATUS_WAITING = 5;
export const ADAPTER_STATUS_PHONE_NOT_CONNECTED = 6;
export const ADAPTER_STATUS_WAIT_FOR_MESSAGE = 7;
export const ADAPTER_STATUS_WRONG_WEBHOOK = 8;
export const ADAPTER_STATUS_RUNNING = 10;
export const ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_EXPIRED = 21;
export const ADAPTER_STATUS_TELEGRAM_ACCESS_TOKEN_INVALID = 22;


// Message
export const CHAT_TYPE_PERSONAL = 1;
export const CHAT_TYPE_GROUP = 2;
export const CHAT_TYPE_CHANNEL = 3;

export const MESSAGE_DIRECTION_RECEIVED = 1;
export const MESSAGE_DIRECTION_SENT = 2;
export const MESSAGE_DIRECTION_SYSTEM = 3;

export const MESSAGE_CONTENT_TYPE_TEXT = 1;
export const MESSAGE_CONTENT_TYPE_IMAGE = 2;
export const MESSAGE_CONTENT_TYPE_CONTACT = 3;
export const MESSAGE_CONTENT_TYPE_VOICE = 4;
export const MESSAGE_CONTENT_TYPE_REMOVE = 5;
export const MESSAGE_CONTENT_TYPE_COMMAND = 6;
export const MESSAGE_CONTENT_TYPE_FILE = 8;
export const MESSAGE_CONTENT_TYPE_INLINE = 9;
export const MESSAGE_CONTENT_TYPE_SYSTEM_INFO = 7;
export const MESSAGE_CONTENT_TYPE_SYSTEM_ERROR = 10;

export const MESSAGE_STATUS_NEW = 1;
export const MESSAGE_STATUS_SENT = 3;
export const MESSAGE_STATUS_FAILED = 5;
export const MESSAGE_STATUS_DELIVERED = 10;
export const MESSAGE_STATUS_READ = 13;
export const MESSAGE_STATUS_DELETED = 15;

// CustomField
export const CUTOM_FIELD_TYPE_TEXT = 'text'
export const CUTOM_FIELD_TYPE_NUMBER = 'number'
export const CUTOM_FIELD_TYPE_BOOL = 'bool'
export const CUTOM_FIELD_TYPE_IMAGE = 'image'
export const CUTOM_FIELD_TYPE_FILE = 'file'
export const CUTOM_FIELD_TYPE_DATE = 'date'
export const CUTOM_FIELD_TYPE_DATETIME = 'datetime'
export const CUTOM_FIELD_TYPE_JSON = 'json'
export const CUTOM_FIELD_TYPE_LIST_TEXT = 'list_text'
export const CUTOM_FIELD_TYPE_LIST_NUMBER = 'list_number'

export const CUTOM_FIELD_OPERATION_SET_EXACT = 'set_exact'
export const CUTOM_FIELD_OPERATION_EXEC_FORMULA = 'exec_formula'
export const CUTOM_FIELD_OPERATION_SET_DATETIME_NOW = 'set_datetime_now'
export const CUTOM_FIELD_OPERATION_SET_FROM_OTHER_FIELD = 'set_from_other_field'


// Step
export const STEP_TYPE_TEXT = 1;
export const STEP_TYPE_HSM = 6;
export const STEP_TYPE_QUESTION = 10;
export const STEP_TYPE_STORE_LEAD = 13;
export const STEP_TYPE_CHOICE_LANGUAGE = 14;
export const STEP_TYPE_CATALOG = 2;
export const STEP_TYPE_BASKET = 3;
export const STEP_TYPE_STORE_ORDER = 4;
export const STEP_TYPE_CHOICE = 16;
export const STEP_TYPE_ACTION = 17;
export const STEP_TYPE_SMART_DELAY = 18;
export const STEP_TYPE_CONDITION = 19;
export const STEP_TYPE_RANDOMIZER = 20;
export const STEP_TYPE_HTTP_REQUEST = 5;
export const STEP_TYPE_FORMULA = 21;
export const STEP_TYPE_SOAP_REQUEST = 7;
export const STEP_TYPE_COMBINED = 22;


// Buttons
export const MENU_BUTTON_NEXT_STEP = 1;
export const MENU_BUTTON_PHONE_NUMBER = 2;

export const INLINE_BUTTON_TYPE_NEXT_STEP = 1;
export const INLINE_BUTTON_TYPE_LINK = 2;
export const INLINE_BUTTON_TYPE_PHONE = 3;


// Condition
export const CONDITION_ARG_TAG = 1;
export const CONDITION_ARG_FORMULA = 2;
export const CONDITION_ARG_BOT = 3;
export const CONDITION_SYSTEM_FIELD = 4;
export const CONDITION_CUSTOM_FIELD = 5;
export const CONDITION_CURRENT_DATE = 6;
export const CONDITION_CURRENT_DATETIME = 7;


// Nav
export const METIS_MENU_CUSTOM_PREFIX = 'custom-';
export const METIS_MENU_BILLING = 'custom-metis-menu-billing';


// Language
export const LANGUAGE_PROPERTIES_BY_LANG_CODE = {
    'en': {
        name: 'English',
        flag: 'GB'
    },
    'ru': {
        name: 'Русский',
        flag: 'RU'
    },
    'es': {
        name: 'Español',
        flag: 'ES'
    },
    // 'pt': {
    //     name: 'Português',
    //     flag: 'PT'
    // }
};
export const LANGUAGE_DEFAULT_LANG_CODE = 'en';

// Billing
export const CHARGEBEE_TARIFF_FREE = 'free';
export const CHARGEBEE_TARIFF_PRO_RU = 'pro_ru';

export const CHARGEBEE_SUBSCRIPTION_STATUS_FUTURE = 'future';
export const CHARGEBEE_SUBSCRIPTION_STATUS_IN_TRIAL = 'in_trial';
export const CHARGEBEE_SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const CHARGEBEE_SUBSCRIPTION_STATUS_NON_RENEWING = 'non_renewing';
export const CHARGEBEE_SUBSCRIPTION_STATUS_PAUSED = 'paused';
export const CHARGEBEE_SUBSCRIPTION_STATUS_CANCELLED = 'cancelled';

export const CHARGEBEE_ADDON_WABA = 'waba-instance';
export const CHARGEBEE_ADDON_INSTAGRAM_DIRECT_INSTANCE = 'instagram-direct-instance';
export const CHARGEBEE_ADDON_WHATSAPP_WEB_INSTANCE = 'chat-api-instance';
export const CHARGEBEE_ADDON_OPERATOR_SEAT = 'operator-seat';

export const CONTACT_SALES_REQUEST_TYPE_WABA_CONTRACT = 'waba-contract';
export const CONTACT_SALES_REQUEST_TYPE_WA_TRIAL = 'wa-trial';
export const CONTACT_SALES_REQUEST_TYPE_INSTA_TRIAL = 'instagram-trial';

// RetailCRM register
export const RETAIL_CRM_MESSENGER_TO_MODULE = {
  'whatsapp': 'ht_whatsapp',
  'instagram': 'ht_instagram',
};

// Auth
export const WA_AUTH = 'https://go.eu.hightouch.ai/lKEMzg';
export const TG_AUTH = 'https://go.eu.hightouch.ai/Bxktvc';

// Registration
export const REGISTRATION_BOT_LINK_BASE = 'https://go.hightouch.ai/CcGlyA';

// Registration
export const REGISTRATION_BOT_LINK_TELEGRAM = '/api/v2/auth/login/quick/tg/link';

// Events
export const EVENT_ADMIN_OPEN_APP = 'Admin Open App';
export const EVENT_ADMIN_CLICK_SUPPORT_BUTTON = 'Admin Clicks Support Button';

// Timeouts
export const TIMEOUT_API = 30 * 1000;
export const TIMEOUT_SERVICE_CORE = 30 * 1000;
export const TIMEOUT_SERVICE_AUTH = 15 * 1000;
export const TIMEOUT_SERVICE_BROADCAST = 60 * 1000;
export const TIMEOUT_SERVICE_AVITO = 15 * 1000;
export const TIMEOUT_FILE_UPLOAD_TO_CDN = 30 * 1000;
export const TIMEOUT_EXCEL_EXPORT = 60 * 60 * 1000;

// Support
export const SUPPORT_BOT_LINK_BASE = 'https://go.hightouch.ai/ncyOOU';
export const SUPPORT_BOT_LINK_BASE_WA = 'https://go.eu.hightouch.ai/lXKKik';
export const SUPPORT_BOT_LINK_BASE_TG = 'https://go.eu.hightouch.ai/nngYBc';
export const SUPPORT_SOURCE_HIGHTOUCH = 'hightouch';
export const SUPPORT_SOURCE_CHAT_API = 'chat_api';
export const SUPPORT_SOURCE_RETAIL_CRM = 'retail_crm';

// Channel registration
export const CHANNEL_REGISTER_BOT_LINK_BASE = 'https://go.eu.hightouch.ai/XqPrFn';
export const CHANNEL_REGISTER_WABA = 'waba';
export const CHANNEL_REGISTER_WHATSAPP = 'whatsapp';

// Landing
export const LANDING_BANNER_DEFAULT_BOT_LINK_BASE = 'https://go.eu.hightouch.ai/KWRaWU?utm_referrer=dashboard';
