import React from 'react';
import {useTranslation} from "react-i18next";
import {withRouter} from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import {connect} from "react-redux";
import {Button} from "reactstrap";
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';

import {setEnableMobileMenu} from '../actions/ThemeOptions';
import {setUpgradeToPROModalOpen} from "../actions/Billing";
import {
    CHARGEBEE_SUBSCRIPTION_STATUS_IN_TRIAL,
    SHOP_SOURCE_CHAT_API,
    SHOP_SOURCE_HIGHTOUCH,
    SHOP_SOURCE_RETAIL_CRM_WABA_RU,
    SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU,
} from "../constants";
import ExtendedLink from "../Components/ExtendedLink";
import {getSupportLink, getTgSupportLink} from "../helpers/links";


const Nav = (
    {
        activeShop,
        billingConfig,
        user,

        setEnableMobileMenu,
        setUpgradeToPROModalOpen,
    }
) => {
    const {t, i18n} = useTranslation();

    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;
    const isRetailCRM = (
        get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_WABA_RU
        || get(activeShop, 'source') === SHOP_SOURCE_RETAIL_CRM_INSTAGRAM_RU
    );
    const activeShopId = get(activeShop, 'id');

    let mainNav;
    if (isChatApi) {
        mainNav = [
            {
                icon: 'fa fas fa-rocket',
                label: t('layout.vertical_nav.menu.dashboard', 'Dashboard'),
                to: `#/${activeShopId}/dashboard/`,
            },
            {
                icon: 'fa fas fa-share-alt-square',
                label: t('layout.vertical_nav.menu.flow_builder', 'Flow builder'),
                to: `#/${activeShopId}/flows/`,
            },
            // {
            //     icon: 'pe-7s-user',
            //     label: t('layout.vertical_nav.menu.audience.title', 'Audience'),
            //     content: [
            //         {
            //             label: t('layout.vertical_nav.menu.audience.users', 'Subscribers'),
            //             to: `#/${activeShopId}/audience/bot_users/`,
            //         },
            //         {
            //             label: t('layout.vertical_nav.menu.audience.groups', 'Groups'),
            //             to: `#/${activeShopId}/audience/groups/`,
            //         },
            //         {
            //             icon: 'pe-7s-note2',
            //             label: t('layout.vertical_nav.menu.audience.leads', 'Leads'),
            //             to: `#/${activeShopId}/audience/leads/`,
            //         },
            //     ]
            // },
            {
                icon: 'fa fas fa-comments',
                label: t('layout.vertical_nav.menu.chats', 'Chats'),
                to: `#/${activeShopId}/chats/`,
            },
            {
                icon: 'fa fas fa-bullhorn',
                label: t('layout.vertical_nav.menu.broadcasts', 'Broadcasts'),
                to: `#/${activeShopId}/broadcasts/tasks/`,
            },
            {
                icon: 'fa fas fa-magnet',
                label: t('layout.vertical_nav.menu.growth.title', 'Growth Tools'),
                content: [
                    {
                        label: t('layout.vertical_nav.menu.growth.chat_links', 'Short URLs'),
                        to: `#/${activeShopId}/growth/short_links/`,
                    },
                    {
                        label: t('layout.vertical_nav.menu.growth.website_icon_link', 'Website icon link'),
                        to: `#/${activeShopId}/growth/website_icon_links/`,
                    },
                    {
                        icon: 'pe-7s-plugin',
                        label: t('layout.vertical_nav.menu.automation.keywords', 'Keywords'),
                        to: `#/${activeShopId}/automation/keywords/`,
                    },
                ],
            },
            {
                icon: 'fa fas fa-cog',
                label: t('layout.vertical_nav.menu.settings.title', 'Settings'),
                content: [
                    {
                        icon: 'pe-7s-home',
                        label: t('layout.vertical_nav.menu.settings.common', 'Common'),
                        to: `#/${activeShopId}/settings/common/`,
                    },
                    {
                        icon: 'pe-7s-diskette',
                        label: t('layout.vertical_nav.menu.settings.custom_fields', 'Custom fields'),
                        to: `#/${activeShopId}/settings/custom_fields/`,
                    },
                    {
                        icon: 'pe-7s-ticket',
                        label: t('layout.vertical_nav.menu.settings.tags', 'Tags'),
                        to: `#/${activeShopId}/settings/tags/`,
                    },
                    // {
                    //     icon: 'pe-7s-plugin',
                    //     label: t('layout.vertical_nav.menu.automation.keywords', 'Keywords'),
                    //     to: `#/${activeShopId}/automation/keywords/`,
                    // },
                    {
                        icon: 'pe-7s-volume2',
                        label: t('layout.vertical_nav.menu.settings.notifications', 'Notification channels'),
                        to: `#/${activeShopId}/settings/notifications/`,
                    },
                    {
                        icon: 'pe-7s-card',
                        label: t('layout.vertical_nav.menu.settings.payments', 'In bot payments'),
                        to: `#/${activeShopId}/settings/payment_gates/`,
                    },
                    // {
                    //     icon: 'pe-7s-cart',
                    //     label: t('layout.vertical_nav.menu.shop.title', 'Shop'),
                    //     content: [
                    //         {
                    //             label: t('layout.vertical_nav.menu.shop.categories', 'Categories'),
                    //             to: `#/${activeShopId}/catalog/categories/`,
                    //         },
                    //         {
                    //             icon: 'pe-7s-bicycle',
                    //             label: t('layout.vertical_nav.menu.shop.products', 'Products'),
                    //             to: `#/${activeShopId}/catalog/products/`,
                    //         },
                    //         {
                    //             icon: 'pe-7s-credit',
                    //             label: t('layout.vertical_nav.menu.shop.orders', 'Orders'),
                    //             to: `#/${activeShopId}/catalog/orders/`,
                    //         },
                    //     ],
                    // },
                ],
            },
            {
                icon: 'fa fas fa-question-circle',
                label: t('layout.vertical_nav.menu.help.chat_api_docs.title', 'Documentation'),
                to: t('layout.vertical_nav.menu.help.chat_api_docs.link', 'https://gugaevkirill.gitbook.io/chat-api-flow-builder/'),
                externalLink: true,
            },
            {
                icon: 'fa fab fa-whatsapp',
                label: t('layout.vertical_nav.menu.help.support_via_messenger', 'Support via WhatsApp'),
                to: getSupportLink(),
                externalLink: true,
            },
            {
                icon: 'fa fab fa-telegram-plane',
                label: t('layout.vertical_nav.menu.help.support_via_telegram', 'Support via Telegram'),
                to: getTgSupportLink(),
                externalLink: true,
            }
        ];
    } else if (isRetailCRM) {
        mainNav = [
            {
                icon: 'fa fas fa-rocket',
                label: t('layout.vertical_nav.menu.dashboard', 'Dashboard'),
                to: `#/${activeShopId}/dashboard/`,
            },
            {
                icon: 'fa fas fa-cog',
                label: t('layout.vertical_nav.menu.settings.hsm_templates', 'HSM-templates'),
                to: `#/${activeShopId}/settings/waba_templates/`,
            },
            {
                icon: 'fa fas fa-question-circle',
                label: t('layout.vertical_nav.menu.help.support_whatsapp', 'Support via WhatsApp'),
                to: `https://go.hightouch.ai/Qbhjs`,
                externalLink: true,
            },
        ];
    } else {
        mainNav = [
            {
                icon: 'fa fas fa-bullseye',
                label: t('layout.vertical_nav.menu.channels', 'Channels'),
                to: `#/${activeShopId}/channels/`,
            },
            {
                icon: 'fa fas fa-users',
                label: t('layout.vertical_nav.menu.audience.title', 'Audience'),
                content: [
                    {
                        label: t('layout.vertical_nav.menu.audience.users', 'Subscribers'),
                        to: `#/${activeShopId}/audience/bot_users/`,
                    },
                    {
                        label: t('layout.vertical_nav.menu.audience.groups', 'Groups'),
                        to: `#/${activeShopId}/audience/groups/`,
                    },
                    {
                        icon: 'pe-7s-note2',
                        label: t('layout.vertical_nav.menu.audience.leads', 'Leads'),
                        to: `#/${activeShopId}/audience/leads/`,
                    },
                ]
            },
            {
                icon: 'fa fas fa-comments',
                label: t('layout.vertical_nav.menu.chats', 'Chats'),
                to: `#/${activeShopId}/chats/`,
            },
            {
                icon: 'fa fas fa-magnet',
                label: t('layout.vertical_nav.menu.growth.title', 'Growth Tools'),
                content: [
                    {
                        label: t('layout.vertical_nav.menu.growth.chat_links', 'Short URLs'),
                        to: `#/${activeShopId}/growth/short_links/`,
                    },
                    {
                        label: t('layout.vertical_nav.menu.growth.website_icon_link', 'Website icon link'),
                        to: `#/${activeShopId}/growth/website_icon_links/`,
                    },
                    {
                        icon: 'pe-7s-plugin',
                        label: t('layout.vertical_nav.menu.automation.keywords', 'Keywords'),
                        to: `#/${activeShopId}/automation/keywords/`,
                    },
                ],
            },
            {
                icon: 'fa fas fa-bullhorn',
                label: t('layout.vertical_nav.menu.broadcasts', 'Broadcasts'),
                to: `#/${activeShopId}/broadcasts/tasks/`,
            },
            // {
            //     icon: 'pe-7s-plugin',
            //     label: t('layout.vertical_nav.menu.automation.title', 'Automation'),
            //     content: [
            // {
            //     label: t('layout.vertical_nav.menu.automation.triggers', 'Triggers'),
            //     to: `#/${activeShopId}/automation/triggers/`,
            // },
            //     ],
            // },
            {
                icon: 'fa fas fa-share-alt-square',
                label: t('layout.vertical_nav.menu.flow_builder', 'Flow builder'),
                // to: `#/${activeShopId}/flows/`,

                content: user?.is_superuser ? [
                    {
                        icon: 'fa fas fa-share-alt-square',
                        label: t('layout.vertical_nav.menu.templates', 'Templates'),
                        to: `#/${activeShopId}/templates/`,
                    },
                    {
                        icon: 'fa fas fa-share-alt-square',
                        label: t('layout.vertical_nav.menu.flows', 'Flows'),
                        to: `#/${activeShopId}/flows/`,
                    },
                ] : [
                    {
                        icon: 'fa fas fa-share-alt-square',
                        label: t('layout.vertical_nav.menu.flows', 'Flows'),
                        to: `#/${activeShopId}/flows/`,
                    },
                ]
            },
            // {
            //     icon: 'pe-7s-network',
            //     label: t('layout.vertical_nav.menu.steps', 'Steps'),
            //     to: `#/${activeShopId}/steps/steps/`,
            // },
            {
                icon: 'fa fas fa-cog',
                label: t('layout.vertical_nav.menu.settings.title', 'Settings'),
                content: [
                    {
                        icon: 'pe-7s-home',
                        label: t('layout.vertical_nav.menu.settings.common', 'Common'),
                        to: `#/${activeShopId}/settings/common/`,
                    },
                    {
                        icon: 'pe-7s-message',
                        label: t('layout.vertical_nav.menu.settings.live_chat', 'Live chat'),
                        to: `#/${activeShopId}/settings/live_chat/`,
                    },
                    {
                        icon: 'pe-7s-diskette',
                        label: t('layout.vertical_nav.menu.settings.custom_fields', 'Custom fields'),
                        to: `#/${activeShopId}/settings/custom_fields/`,
                    },
                    {
                        icon: 'pe-7s-ticket',
                        label: t('layout.vertical_nav.menu.settings.tags', 'Tags'),
                        to: `#/${activeShopId}/settings/tags/`,
                    },
                    // {
                    //     icon: 'pe-7s-plugin',
                    //     label: t('layout.vertical_nav.menu.automation.keywords', 'Keywords'),
                    //     to: `#/${activeShopId}/automation/keywords/`,
                    // },
                    {
                        icon: 'pe-7s-volume2',
                        label: t('layout.vertical_nav.menu.settings.notifications', 'Notification channels'),
                        to: `#/${activeShopId}/settings/notifications/`,
                    },
                    {
                        icon: 'pe-7s-card',
                        label: t('layout.vertical_nav.menu.settings.payments', 'In bot payments'),
                        to: `#/${activeShopId}/settings/payment_gates/`,
                    },
                    // {
                    //     icon: 'pe-7s-cart',
                    //     label: t('layout.vertical_nav.menu.shop.title', 'Shop'),
                    //     content: [
                    //         {
                    //             label: t('layout.vertical_nav.menu.shop.categories', 'Categories'),
                    //             to: `#/${activeShopId}/catalog/categories/`,
                    //         },
                    //         {
                    //             icon: 'pe-7s-bicycle',
                    //             label: t('layout.vertical_nav.menu.shop.products', 'Products'),
                    //             to: `#/${activeShopId}/catalog/products/`,
                    //         },
                    //         {
                    //             icon: 'pe-7s-credit',
                    //             label: t('layout.vertical_nav.menu.shop.orders', 'Orders'),
                    //             to: `#/${activeShopId}/catalog/orders/`,
                    //         },
                    //     ],
                    // },
                    {
                        icon: 'pe-7s-chat',
                        label: t('layout.vertical_nav.menu.settings.hsm_templates', 'HSM-templates'),
                        to: `#/${activeShopId}/settings/waba_templates/`,
                    },
                    {
                        icon: 'pe-7s-users',
                        label: t('layout.vertical_nav.menu.settings.team', 'Team'),
                        to: `#/${activeShopId}/settings/team/`,
                    },
                    // TODO @blademor - вернуть потом
                    // {
                    //     label: t('layout.vertical_nav.menu.settings.billing', 'Billing'),
                    //     to: METIS_MENU_BILLING,
                    // },
                    {
                        icon: 'pe-7s-tools',
                        label: t('layout.vertical_nav.menu.settings.integrations', 'Integrations'),
                        to: `#/${activeShopId}/settings/plugins/`,
                    }
                ],
            },
            {
                icon: 'fa fas fa-question-circle',
                label: t('layout.vertical_nav.menu.help.title', 'Help'),
                content: [
                    {
                        label: t('layout.vertical_nav.menu.help.knowledge_base', 'Knowledge base'),
                        to: `https://docs.hightouch.ai/`,
                        externalLink: true,
                    },
                    {
                        label: t('layout.vertical_nav.menu.help.api_docs', 'API - docs'),
                        to: `https://docs.hightouch.ai/api`,
                        externalLink: true,
                    },
                    {
                        label: t('layout.vertical_nav.menu.help.support_via_messenger', 'Support via WhatsApp'),
                        to: getSupportLink(),
                        externalLink: true,
                    },
                    {
                        icon: 'fa fab fa-telegram-plane',
                        label: t('layout.vertical_nav.menu.help.support_via_telegram', 'Support via Telegram'),
                        to: getTgSupportLink(),
                        externalLink: true,
                    }
                ]
            },

        ];
    }

    if (user?.is_superuser){
        mainNav.push({
            icon: 'fa fas fa-paint-roller',
            label: t('layout.vertical_nav.menu.styles_page', 'Styles page'),
            to: `#/styles-typography/`,
        })
    }

    // Trial period
    let billingBlock = undefined;
    if (!isEmpty(billingConfig)
        && activeShop.source === SHOP_SOURCE_HIGHTOUCH
        && billingConfig.subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS_IN_TRIAL
    ) {
        const currentTs = moment().unix();
        const trialStart = billingConfig.subscription.trial_start;
        const trialEnd = billingConfig.subscription.trial_end;
        const percentage = 100 * (currentTs - trialStart) / (trialEnd - trialStart);
        billingBlock = <div className='pb-3 pt-4 menu-upgrade-button'>
            <div className="border-top d-flex justify-content-start align-items-center py-2">
                <div className='p-2'
                     style={{
                         width: '80px',
                     }}
                >
                    <CircularProgressbar
                        value={percentage}
                        strokeWidth={16}
                        text={moment.unix(trialEnd).diff(moment(), "days")}
                        styles={buildStyles({
                            pathColor: '#0ecb6b',
                            trailColor: '#d7dbe5',
                            textColor: 'black',
                            textSize: 36,
                        })}
                    />
                </div>
                <div>
                    <small>{t('layout.vertical_nav.trial_notification_title', 'Days left until the end of the trial period')}</small>
                </div>
            </div>
            <Button
                size="lg"
                color="primary"
                className="w-100"
                onClick={e => setUpgradeToPROModalOpen(true)}
            >
                {t('layout.vertical_nav.button_become_pro', 'Upgrade to PRO')}
            </Button>
        </div>
    }

    return <>
        <MetisMenu
            content={mainNav}
            activeLinkFromLocation
            className="vertical-nav-menu mt-4"
            iconNamePrefix=""
            classNameStateIcon="fa fas fa-angle-down"
            LinkComponent={ExtendedLink}
            onSelected={() => setEnableMobileMenu(false)}
        />

        {billingBlock}
    </>;
}


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    billingConfig: state.Billing.billingConfig,
    user: state.Auth.user
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setUpgradeToPROModalOpen: open => dispatch(setUpgradeToPROModalOpen(open)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
