import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'

import ThemeOptions from './ThemeOptions';
import Auth from './Auth';
import Shops from './Shops';
import Bots from './Bots'
import APITable from './APITable'
import APIForm from './APIForm'
import Billing from './Billing'
import Steps from './Steps'
import Chats from "./Chats";
import Audience from "./Audience";
import Connect from "./Connect";
import Statistic from "./Statistic";
import Broadcast from "./Broadcast";
import Plugins from "./Plugins";
import Notifications from "./Notifications";
import Templates from "./Templates";
import FlowTemplates from "./FlowTemplates";


export default (history) => combineReducers({
    ThemeOptions,
    Auth,
    Shops,
    Bots,
    APITable,
    APIForm,
    Billing,
    Steps,
    Chats,
    Broadcast,
    Audience,
    Connect,
    Statistic,
    Plugins,
    Notifications,
    Templates,
    FlowTemplates,
    router: connectRouter(history),
});
