import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DefaultPortModel, PortWidget} from '@projectstorm/react-diagrams';
import get from "lodash/get";

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation, Trans} from "react-i18next";


const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
const TEXT_COLORS = ['text-primary', 'text-success', 'text-info', 'text-warning'];


class RandomizerNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'randomizer-node';
    }

    getHeaderClassName() {
        return 'bg-night-fade bg-opacity-3';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-shuffle"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.random.name', 'Randomizer')}
        </>;
    }

    getBody() {
        const that = this;
        const ports = this.props.node.getPorts();
        const options = this.props.node.options;
        const split_groups = get(options.step, 'state.randomizer_config.split_groups', []);

        const splitGroupsHtml = [];
        let totalPercent = 0;
        split_groups.map((group, idx) => {
            totalPercent += group.distribution;

            const port = get(ports, `split-${that.id}-${idx}`);
            const textCls = TEXT_COLORS[idx % TEXT_COLORS.length];

            splitGroupsHtml.push(
                <div className={`split-group border-bottom py-3 d-flex justify-content-between font-size-xlg ${textCls}`} key={idx}>
                    <span>{ALPHABET[idx]}</span>{' '}
                    <span>{group.distribution}%</span>
                    {
                        port && <PortWidget engine={this.props.engine} port={port} className="out-port">
                            <div className="circle-port"/>
                        </PortWidget>
                    }
                </div>
            );
        });

        return <>
            {
                totalPercent !== 100 && <div className='split-group border-bottom py-3 text-center text-danger'>
                    <Trans i18nKey="pages.main.flow_builder.node.random.error.wrong_probabilities_sum" t={this.props.t}>
                        Sum of all probabilities (total probability) must be equal 100%.<br/>
                        The sum is <strong>{{ totalPercent }}</strong> now
                    </Trans>
                </div>
            }
            {splitGroupsHtml}
        </>;
    }

    getErrors() {
        return '';
    }
}

export const RandomizerNodeWidget = withTranslation()(RandomizerNodeWidget_);

export class RandomizerNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'randomizer-node',
            disableNextStepPort: true,
            disableCascadePort: true,
        });

        const that = this;
        const split_groups = get(this.options.step, 'state.randomizer_config.split_groups', []);
        split_groups.map((group, idx) => {
            this.addPort(
                new DefaultPortModel({
                    in: false,
                    name: `split-${that.id}-${idx}`,
                    label: '',
                    splitGroupCfg: {
                        idx: idx,
                    },
                })
            );
        });
    }
}


export class RandomizerNodeFactory extends AbstractReactFactory {
    constructor() {
        super('randomizer-node');
    }

    generateModel(event) {
        return new RandomizerNodeModel();
    }

    generateReactWidget(event) {
        return <RandomizerNodeWidget engine={this.engine} node={event.model}/>;
    }
}
