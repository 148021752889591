import React from 'react';
import get from "lodash/get";
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';

import {AbstractNodeModel, AbstractNodeWidget} from "./AbstractNode";
import {withTranslation} from "react-i18next";


class SOAPRequestNodeWidget_ extends AbstractNodeWidget {
    constructor() {
        super();

        this.type = 'soap-request-node';
    }
    
    getCascadeStepLabel() {
        return this.props.t('pages.main.flow_builder.node.soap_request.cascade_step', 'Request failed')
    }

    getHeaderClassName() {
        return 'bg-mixed-hopes bg-opacity-3';
    }

    getHeader() {
        const options = this.props.node.options;
        return <>
            <i className="pe-7s-refresh"> </i> {options.step.name || this.props.t('pages.main.flow_builder.node.soap_request.name', 'SOAP-request')}
        </>;
    }

    getBody() {
        const options = this.props.node.options;
        const requestConfig = get(options.step, 'state.request_config');

        if (!requestConfig) {
            return '';
        }

        return <>
            <div><strong>{requestConfig.method}</strong> {requestConfig.url}</div>
            <div>{['POST', 'PUT', 'PATCH'].indexOf(requestConfig.method) && requestConfig.body}</div>
        </>;
    }

    getErrors() {
        const options = this.props.node.options;
        const url = get(options.step, 'state.soap_config.wsdl_uri');

        const errors = [];
        if (!url) {
            errors.push(this.props.t('pages.main.flow_builder.node.soap_request.errors.url_is_not_specified', 'Please specify WSDL URL'));
        }

        return errors.map((error, idx) => <div className='py-2' key={idx}>
                <i className='text-danger opacity-9'>{error}</i>
            </div>
        );
    }
}

export const SOAPRequestNodeWidget = withTranslation()(SOAPRequestNodeWidget_);

export class SOAPRequestNodeModel extends AbstractNodeModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'soap-request-node',
            alwaysEnableCascadePort: true,
            disableCascadePort: true,
        });
    }
}


export class SOAPRequestNodeFactory extends AbstractReactFactory {
    constructor() {
        super('soap-request-node');
    }

    generateModel(event) {
        return new SOAPRequestNodeModel();
    }

    generateReactWidget(event) {
        return <SOAPRequestNodeWidget engine={this.engine} node={event.model}/>;
    }
}
