import React, {Suspense, useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import get from "lodash/get";
import TagManager from "react-gtm-module";

import PrivateRouteWithShops from '../../Components/PrivateRouteWithShops'
import Preloader from '../../Components/Preloader'
import UpgradeToPRO from '../../Components/UpgradeToPRO'
import AuthTokenAuthorizationWrapper from '../../Pages/Auth/Components/AuthTokenAuthorizationWrapper';

import HSMTemplates from '../../Pages/Settings/HSMTemplates';
import Plugins from '../../Pages/Settings/Plugins/index';
import InstallPlugin from '../../Pages/Settings/Plugins/InstallPlugin';
import Common from '../../Pages/Settings/Common';
import Team from '../../Pages/Settings/Team';
import LiveChat from '../../Pages/Settings/LiveChat';
import Auth from '../../Pages/Auth';
import Dashboard from '../../Pages/Main/Dashboard';
import Channels from '../../Pages/Main/Channels';
import Chats from '../../Pages/Chats/index';
import BotUsers from '../../Pages/Audience/BotUsers';
import Groups from '../../Pages/Audience/Groups';
import Leads from '../../Pages/Audience/Leads';
import BroadcastTasks from '../../Pages/Broadcasts/Broadcasts';
import Categories from '../../Pages/Catalog/Categories';
import Products from '../../Pages/Catalog/Products';
import Orders from '../../Pages/Catalog/Orders';
import PaymentGates from '../../Pages/Settings/PaymentGates';
import NotificationChannels from '../../Pages/Settings/NotificationChannels';
import Tags from '../../Pages/Settings/Tags';
import CustomFields from '../../Pages/Settings/CustomFields';
import Flows from '../../Pages/Main/Flow/Flows';
import Keywords from '../../Pages/Automation/Keywords';
import Triggers from '../../Pages/Automation/Triggers';
import ShortLinks from '../../Pages/Growth/ShortLinks';
import WebsiteIconLinks from '../../Pages/Growth/WebsiteIconLinks';
import {SHOP_SOURCE_CHAT_API} from "../../constants";

// import StylesTypographyPage from '../../Pages/StylesTypographyPage';
const StylesTypographyPage = React.lazy(() => import('../../Pages/StylesTypographyPage/StylesTypography')) ;
const Templates = React.lazy(() => import('../../Pages/Main/Flow/Templates')) ;



const AppMain = (
    {
        activeShop,
        isAuthenticated,
        user,
    }
) => {
    const activeShopId = get(activeShop, 'id', 0);
    const isChatApi = get(activeShop, 'source') === SHOP_SOURCE_CHAT_API;

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
    };

    useEffect(() => {
        // Initialize only in web browser
        if (typeof document !== 'undefined') {
            TagManager.initialize(tagManagerArgs);
        }
    }, []);

    return <AuthTokenAuthorizationWrapper>
        {/* Auth */}
        
            <Route exact path="/" render={() => {
                if (isAuthenticated) {
                    if (isChatApi) {
                        return <Redirect to={`/${activeShopId}/flows/`}/>;
                    }
                    return <Redirect to={`/${activeShopId}/dashboard/`}/>;
                } else {
                    return <Redirect to={'/auth/login'}/>
                }
            }}/>

        <Suspense fallback={<Preloader/>}>
            <Route path="/auth" component={Auth}/>
        </Suspense>

        {/* Main menu */}
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/dashboard/"
                component={Dashboard}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/channels/"
                component={Channels}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/flows/"
                component={Flows}
            />
        </Suspense>

        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/templates/"
                component={Templates}
            />
        </Suspense>

        {/* Audience */}
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/chats/"
                component={Chats}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/audience/bot_users/"
                component={BotUsers}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/audience/groups/"
                component={Groups}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/audience/leads/"
                component={Leads}
            />
        </Suspense>

        {/* Broadcasts */}
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/broadcasts/tasks/"
                component={BroadcastTasks}
            />
        </Suspense>

        {/* Growth */}
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/growth/short_links/"
                component={ShortLinks}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/growth/website_icon_links/"
                component={WebsiteIconLinks}
            />
        </Suspense>

        {/* Automation */}
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/automation/keywords/"
                component={Keywords}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/automation/triggers/"
                component={Triggers}
            />
        </Suspense>

        {/* Catalog */}
        {/*<Suspense fallback={<Preloader/>}>*/}
        {/*    <PrivateRouteWithShops*/}
        {/*        path="/:shopId/catalog/categories/"*/}
        {/*        component={Categories}*/}
        {/*    />*/}
        {/*</Suspense>*/}
        {/*<Suspense fallback={<Preloader/>}>*/}
        {/*    <PrivateRouteWithShops*/}
        {/*        path="/:shopId/catalog/products/"*/}
        {/*        component={Products}*/}
        {/*    />*/}
        {/*</Suspense>*/}
        {/*<Suspense fallback={<Preloader/>}>*/}
        {/*    <PrivateRouteWithShops*/}
        {/*        path="/:shopId/catalog/orders/"*/}
        {/*        component={Orders}*/}
        {/*    />*/}
        {/*</Suspense>*/}

        {/* Settings */}

        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/common/"
                component={Common}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/custom_fields/"
                component={CustomFields}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/tags/"
                component={Tags}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/notifications/"
                component={NotificationChannels}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/plugins/connect/:slug/"
                component={InstallPlugin}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/plugins/"
                component={Plugins}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/live_chat/"
                component={LiveChat}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/team/"
                component={Team}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/waba_templates/"
                component={HSMTemplates}
            />
        </Suspense>
        <Suspense fallback={<Preloader/>}>
            <PrivateRouteWithShops
                path="/:shopId/settings/payment_gates/"
                component={PaymentGates}
            />
        </Suspense>

        {
            user?.is_superuser &&
            <Suspense fallback={<Preloader/>}>
                <Route
                    exact
                    path="/styles-typography/"
                    component={StylesTypographyPage}
                    // render={()=><h1>HELLO</h1>}

                />
            </Suspense>
        }

        {/* Дополнительные элементы */}
        <Suspense fallback={<Preloader/>}>
            <UpgradeToPRO/>
        </Suspense>

        <ToastContainer/>
    </AuthTokenAuthorizationWrapper>;
};


const mapStateToProps = state => ({
    activeShop: state.Shops.activeShop,
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
});

export default connect(mapStateToProps)(AppMain);
