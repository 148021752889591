import React from 'react';
import {Button, Card, CardBody,} from 'reactstrap';
import Form from "react-jsonschema-form";
import {connect} from "react-redux";
import SweetAlert from "sweetalert-react";
import {useTranslation} from "react-i18next";

import {
    apiFormDeleteSweetalertHide,
    apiFormDeleteSweetalertShow,
    apiFormSetLiveValidate,
} from "../../actions/APIModel";
import DescriptionField from './Fields/DescriptionField';


export const FIELDS_CONFIG = {
    DescriptionField: DescriptionField
};


// TODO: доделать компонент, разобраться с ошибками
// export const TextareaAutosizeWidget = (props) => <TextareaAutosize
//     className="form-control"
//     minRows={3}
//
//     onChange={props.onChange}
//     value={props.value}
//     placeholder={props.placeholder}
//     id={props.id}
//     options={props.options}
//     required={props.required}
//     disabled={props.disabled}
//     readOnly={props.readonly}
//     autoFocus={props.autofocus}
//     onBlur={props.onBlur}
//     onFocus={props.onFocus}
// />;


const FormView = (
    {
        formSchema,
        formUISchema,
        onSubmit,
        onChange,
        liveValidate,
        ArrayFieldTemplate,
        formData,
        buttonSubmitDisabled,
        deleteSweetalertShow,
        isSendingActionRequest,
        deletionDisabled,
        apiFormDeleteSweetalertShow,
        apiFormDeleteSweetalertHide,
        apiFormSetLiveValidate,
        onDelete,
    }
) => {
    const {t, i18n} = useTranslation();

    const buttons = [];
    if (!buttonSubmitDisabled) {
        buttons.push(<Button
                color="primary"
                className=" btn-lg"
                type="submit"
                disabled={isSendingActionRequest}
                onClick={() => {
                    apiFormSetLiveValidate(true)
                }}
                key={1}
            >
                {t('components.form_view.button.send', 'Send')}
            </Button>
        );
    }
    if (!deletionDisabled) {
        buttons.push(<Button
                color="light"
                className=" btn-lg pull-right"
                disabled={isSendingActionRequest}
                onClick={() => {
                    apiFormDeleteSweetalertShow(formData.id)
                }}
                key={2}
            >
                {t('connect_bot.common.remove', 'Remove')}
            </Button>
        );
    }

    return <>
        <Card
            className="main-card mb-3"
            onKeyDown={(event) => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
            }}
        >
            <CardBody>
                <Form
                    schema={formSchema}
                    uiSchema={formUISchema}
                    formData={formData}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    liveValidate={liveValidate}
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    fields={FIELDS_CONFIG}
                    showErrorList={false}
                >
                    {buttons}
                </Form>
            </CardBody>
        </Card>

        <SweetAlert
            title={t('components.api_form.sweetalert_remove_agreement_title', 'Confirm the removal')}
            confirmButtonColor="#d92550"
            show={deleteSweetalertShow}
            text={t('components.api_form.sweetalert_remove_agreement_caption', 'It will be removed permanently.')}
            type="warning"
            showCancelButton
            cancelButtonText={t('bot_card.common.button_cancel', 'Cancel')}
            onConfirm={onDelete}
            onCancel={apiFormDeleteSweetalertHide}
        />
    </>;
}


const mapStateToProps = state => ({
    deleteSweetalertShow: state.APIForm.deleteSweetalertShow,
});

const mapDispatchToProps = dispatch => ({
    apiFormDeleteSweetalertShow: (idToRemove) => dispatch(apiFormDeleteSweetalertShow(idToRemove)),
    apiFormDeleteSweetalertHide: () => dispatch(apiFormDeleteSweetalertHide()),
    apiFormSetLiveValidate: (liveValidate) => dispatch(apiFormSetLiveValidate(liveValidate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormView);
